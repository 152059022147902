<template>
  <div class="bbr-programs--lists-table">
    <v-simple-table>
      <thead v-if="programs.length">
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Week count</th>
          <th>Plan</th>
          <th>
            <v-select
              :items="programRatings"
              v-model="rating"
              label="Ratings"
            ></v-select>
          </th>
          <th v-if="listFor != 'Draft'">Archive</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <tr
          class="clickable new-tab-link-row"
          v-for="program in programs"
          :key="program.id"
        >
          <td style="width: 25%">
            <h2 class="subtitle-1">{{ program.name }}</h2>
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: program.id },
                meta: { stage: 1 },
              }"
              class="row-link"
            />
          </td>
          <td style="width: 40%">
            {{ program.description }}
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: program.id },
                meta: { stage: 1 },
              }"
              class="row-link "
            />
          </td>
          <td>
            {{ program.week_count }}
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: program.id },
                meta: { stage: 1 },
              }"
              class="row-link "
            />
          </td>
          <td>
            {{ program.package_name }}
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: program.id },
                meta: { stage: 1 },
              }"
              class="row-link "
            />
          </td>
          <td>
            <div class="d-flex align-center rating">
              <span class="primary--text rating__label">
                <strong>{{ program[ratingVal] || 0 }}</strong>
              </span>
              <v-rating
                :value="program[ratingVal]"
                half-increments
                readonly
                background-color="primary lighten-3"
                color="primary"
                large
              ></v-rating>
              <span class="grey--text text-caption"
                >({{ program[`${ratingVal}_count`] }})</span
              >
            </div>
          </td>
          <td v-if="listFor != 'Draft'">
            <v-btn
              v-if="listFor != 'Archived'"
              :disabled="loading"
              class="bg-primary-gradient primary ml-auto"
              @click="archive(program, true)"
              >Archive</v-btn
            >
            <v-btn
              v-if="listFor == 'Archived'"
              :disabled="loading"
              class="bg-primary-gradient primary ml-auto"
              @click="archive(program, false)"
              >Unarchive</v-btn
            >
          </td>
        </tr>
      </tbody>
      <table-skeleton-loader v-if="loading" column="4" row />
    </v-simple-table>

    <no-list
      v-if="!programs.length && !loading"
      details="No Available Programs"
    />
    <confirm-dialog
      has-custom-cancel="true"
      ref="confirmArchiving"
      @close="confirmed"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapMutations } from 'vuex'
import Event from '@/services/eventBus'

import { mdiDotsVertical } from '@mdi/js'
import { includes } from 'lodash'

export default {
  name: 'ProgramsListTable',

  directives: {
    infiniteScroll,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    programs: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    listFor: {
      type: String,
      required: true,
    },
  },

  components: {
    TableSkeletonLoader,
    NoList,
    ConfirmDialog,
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
      programRatings: [
        { text: 'Overall Experience', value: 1 },
        { text: 'Nutrition', value: 2 },
        { text: 'Training', value: 3 },
      ],
      rating: 1,
    }
  },

  computed: {
    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },

    redirectPathName() {
      return includes(this.$route.name, 'completed')
        ? 'program.details'
        : 'new.program.details'
    },
    ratingVal() {
      let col
      // 1 - Overall Exp, 2 - Nutrition, 3 - Training
      switch (this.rating) {
        case 2:
          col = 'nutrition_rating'
          break
        case 3:
          col = 'training_rating'
          break
        default:
          col = 'overall_exp_rating'
      }

      return col
    },
  },

  methods: {
    ...mapActions({
      archiveProgram: 'programs/archiveProgram',
    }),

    ...mapMutations({
      updateArchivedList: 'programs/shiftProgramOnArchiveToTargetList',
    }),
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    goTo(program) {
      this.$emit('goTo', program.id)
    },

    async archive(program, setArchive) {
      const confirm = await this.$refs.confirmArchiving.open(
        `${setArchive ? 'Archive' : 'Unarchive'} Program`,
        `Are you sure you want to ${
          setArchive ? 'archive' : 'unarchive'
        } this program?`
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.archiveProgram({ id: program.id, setArchive })
        await this.updateArchivedList({ program, setArchive })

        setTimeout(() => {
          this.loading = false

          if (setArchive) {
            Event.$emit('update-current-programs-list')
          } else {
            Event.$emit('update-archived-programs-list')
          }
        }, 900)
      } catch (err) {
        this.loading = false
        this.$refs.confirmArchiving.open(
          'Error',
          'There was an error archiving the selected event'
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.test-table {
  display: table;
  width: 100%;
}
.test-tr {
  display: table-row;
}
.test-td {
  display: table-cell;
  padding: 10px;
}
.test-:hover {
  background-color: #ccc;
}
</style>
